<template>
  <div>
    <div class="d-flex align-center text-2xl mb-6">
      <span class="headline">Single Criteria Matching</span>
    </div>
    <div>
      <v-row>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By Challenge</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="selectedChallenges"
                  multiple
                  label="Select Challenge"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="challenges"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :error-messages="showChallengeError ? ['You must select at least one item'] : []"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedChallenges.length }} item(s) Selected</span>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByChallenge">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By Innovation Areas</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="selectedTechAreas"
                  multiple
                  label="Select Inn. Areas"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="techAreas"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :error-messages="showTechAreaError ? ['You must select at least one item'] : []"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedTechAreas.length }} item(s) Selected</span>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByTechArea">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By Outcome</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="selectedOutcomes"
                  multiple
                  label="Select Outcome"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="outcomes"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :error-messages="showOutcomeError ? ['You must select at least one item'] : []"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedOutcomes.length }} item(s) Selected</span>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByOutcome">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By Industry</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="selectedIndustries"
                  multiple
                  label="Select Industry"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="industries"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :error-messages="showIndustryError ? ['You must select at least one item'] : []"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedIndustries.length }} item(s) Selected</span>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByIndustry">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By Organisation Type</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="selectedCompanyTypes"
                  multiple
                  label="Select Organisation Type"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="getTopLevelCompanyTypes"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :error-messages="showCompanyTypeError ? ['You must select at least one item'] : []"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedCompanyTypes.length }} item(s) Selected</span>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByCompanyType">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By Sub-Type</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="selectedStages"
                  multiple
                  label="Select Sub-Type"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="getCompanySubTypes"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :error-messages="showStageError ? ['You must select at least one item'] : []"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedStages.length }} item(s) Selected</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action>
                      <v-checkbox :value="selectedStages.includes(item.id)"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.parentName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByStage">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By Tag</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="selectedTags"
                  multiple
                  label="Select Tag"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="tags"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :error-messages="showTagError ? ['You must select at least one item'] : []"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedTags.length }} item(s) Selected</span>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByTag">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By City</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-text-field v-model="location" class="mb-3" label="City" dense outlined hide-details="auto" :error-messages="showLocationError ? ['You must type a location'] : []"></v-text-field>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByLocation">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By Region</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="selectedRegions"
                  multiple
                  label="Select Region"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="regions"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :error-messages="showRegionError ? ['You must select at least one item'] : []"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedRegions.length }} item(s) Selected</span>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByRegion">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">By Country</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="selectedCountries"
                  multiple
                  label="Select Country"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="getCountriesArray"
                  item-text="name"
                  item-value="code"
                  hide-details="auto"
                  :error-messages="showCountryError ? ['You must select at least one item'] : []"
                >
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedCountries.length }} item(s) Selected</span>
                  </template>
                </v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchByCountry">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="3">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">Super Connect Me</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-autocomplete
                  class="mb-3"
                  v-model="superConnectMe"
                  label="Super Connect Me"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="[
                    { name: 'Yes', id: true },
                    { name: 'No', id: false },
                  ]"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :error-messages="showSuperConnectError ? ['You must select one item'] : []"
                ></v-autocomplete>
              </div>
              <div>
                <v-btn class="mt-4" color="primary" outlined small block style="text-transform: none" @click="matchBySuperConnect">
                  Match
                  <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CreateIndividualModal from '@/components/individual/CreateIndividualModal';

export default {
  name: 'SimpleMatch',
  components: {},
  data: () => ({
    showChallengeError: false,
    showTechAreaError: false,
    showOutcomeError: false,
    showStageError: false,
    showIndustryError: false,
    showCountryError: false,
    showRegionError: false,
    showLocationError: false,
    showCompanyTypeError: false,
    showSuperConnectError: false,
    superConnectMe: null,
    showTagError: false,
    selectedChallenges: [],
    selectedTechAreas: [],
    selectedOutcomes: [],
    selectedStages: [],
    selectedIndustries: [],
    selectedTags: [],
    selectedCountries: [],
    selectedRegions: [],
    selectedCompanyTypes: [],
    location: '',
  }),
  computed: {
    ...mapState(['challenges', 'techAreas', 'outcomes', 'industries', 'regions', 'companyTypes', 'tags']),
    ...mapGetters(['getCountriesArray']),
    getTopLevelCompanyTypes(state) {
      return state.companyTypes.filter((c) => !c.parent);
    },
    getCompanySubTypes() {
      return this.$store.getters.getCompanySubTypes.map((type) => {
        let parent;
        if (type.parent) {
          parent = this.$store.state.companyTypes.find((p) => p.id === type.parent);
        }
        return {
          ...type,
          parentName: parent?.name || '',
        };
      });
    },
  },
  created() {},
  methods: {
    ...mapActions(['fetchIndividuals', 'deleteIndividual']),
    openCreateModal() {
      this.$showModal(CreateIndividualModal);
    },
    matchByChallenge() {
      if (this.selectedChallenges.length === 0) {
        this.showChallengeError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { challenge: this.selectedChallenges.join(',') } });
    },
    matchByTechArea() {
      if (this.selectedTechAreas.length === 0) {
        this.showTechAreaError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { techArea: this.selectedTechAreas.join(',') } });
    },
    matchByOutcome() {
      if (this.selectedOutcomes.length === 0) {
        this.showOutcomeError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { outcome: this.selectedOutcomes.join(',') } });
    },
    matchByStage() {
      if (this.selectedStages.length === 0) {
        this.showStageError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { stage: this.selectedStages.join(',') } });
    },
    matchByIndustry() {
      if (this.selectedIndustries.length === 0) {
        this.showIndustryError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { industry: this.selectedIndustries.join(',') } });
    },
    matchByCountry() {
      if (this.selectedCountries.length === 0) {
        this.showCountryError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { country: this.selectedCountries.join(',') } });
    },
    matchByRegion() {
      if (this.selectedRegions.length === 0) {
        this.showRegionError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { region: this.selectedRegions.join(',') } });
    },
    matchByLocation() {
      if (this.location.trim().length === 0) {
        this.showLocationError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { location: this.location } });
    },
    matchByCompanyType() {
      if (this.selectedCompanyTypes.length === 0) {
        this.showCompanyTypeError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { companyType: this.selectedCompanyTypes.join(',') } });
    },
    matchByTag() {
      if (this.selectedTags.length === 0) {
        this.showTagError = true;
        return;
      }
      this.$router.push({ path: '/simple-match-result', query: { tag: this.selectedTags.join(',') } });
    },
    matchBySuperConnect() {
      this.$router.push({ path: '/simple-match-result', query: { superConnectMe: this.superConnectMe } });
    },
  },
};
</script>
